var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('div',{staticStyle:{"width":"75%"}},[_c('v-text-field',{attrs:{"hide-details":"","prepend-icon":"mdi-magnify","label":"Поиск пользователя по e-mail/login"},on:{"input":function($event){return _vm.provideSearch()}},model:{value:(_vm.pagination.search),callback:function ($$v) {_vm.$set(_vm.pagination, "search", $$v)},expression:"pagination.search"}})],1),_c('v-list',[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_vm._l((_vm.pagination.result),function(value){return [_c('v-col',{attrs:{"cols":"6"}},[_c('v-list-item',{on:{"click":function($event){_vm.selectedUser = value}}},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":value.avatar,"contain":""}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(value.username))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(value.email))])],1),_c('v-list-item-action',[(value.roles.includes("moderator"))?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-police-badge")])]}}],null,true)},[_c('span',[_vm._v("Администратор")])])]:(value.roles.includes("curator"))?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-badge-account")])]}}],null,true)},[_c('span',[_vm._v("Куратор")])])]:[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g({staticStyle:{"opacity":".3"}},on),[_vm._v("mdi-account")])]}}],null,true)},[_c('span',[_vm._v("Пользователь")])])]],2)],1)],1)]})],2)],1)],1),_c('v-pagination',{staticClass:"mt-4",attrs:{"length":_vm.totalPages,"color":"teal","total-visible":"7"},on:{"input":function($event){return _vm.paginateUsers()}},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}),(_vm.selectedUser)?[_c('v-dialog',{attrs:{"width":"75%"},model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}},[_c('v-card',[_c('v-container',[_c('v-row',{attrs:{"no-gutters":"","justify":"left","align":"center"}},[_c('div',{staticClass:"pr-2"},[_c('v-avatar',{attrs:{"size":"100"}},[_c('v-img',{attrs:{"src":_vm.selectedUser.avatar}})],1)],1),_c('v-col',[_c('div',{staticClass:"text-h5 font-weight-regular",staticStyle:{"line-height":"1"}},[_vm._v(_vm._s(_vm.selectedUser.username))]),_c('p',{staticClass:"font-weight-light",staticStyle:{"line-height":"1"}},[_vm._v(_vm._s(_vm.selectedUser.email))]),_c('v-row',{staticClass:"pt-2",attrs:{"justify":"start","align":"center","no-gutters":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({class:{ "white--text": _vm.selectedUser.blocked },attrs:{"depressed":"","color":_vm.selectedUser.blocked ? "red lighten-2" : ""},on:{"click":function($event){return _vm.switchBan()}}},on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-"+_vm._s(_vm.selectedUser.blocked ? 'lock' : 'lock-open'))]),_vm._v(_vm._s(_vm.selectedUser.blocked ? 'Заблокирован' : 'Активен'))],1)]}}],null,false,556150029)},[_c('span',[_vm._v("Заблокировать пользователя")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.selectedUser.roles.includes("moderator"))?[_c('v-btn',_vm._g({staticClass:"ml-2 white--text",attrs:{"color":"green lighten-1","depressed":""},on:{"click":_vm.switchModerator}},on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-police-badge")]),_vm._v("Модератор")],1)]:[_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"depressed":""},on:{"click":_vm.switchModerator}},on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-account")]),_vm._v("Пользователь")],1)]]}}],null,false,1187458011)},[_c('span',[_vm._v(_vm._s(_vm.selectedUser.roles.includes("moderator") ? "Забрать" : "Выдать")+" права модератора")])])],1)],1)],1)],1)],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }