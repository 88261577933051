<template lang='pug'>
  v-container(fill-height fluid style='justify-content: center; background: whitesmoke;')
    div(style='display: flex;justify-content: center;')
      div(style='width: 1000px')
        v-list.py-0(:style='{ background: section ? "whitesmoke" : ""}')
          template(v-if='!section')
            div.pa-5
              .text-h5.text-center.font-weight-light.mb-2 Панель управления
              template(v-for='(value) in features')
                v-list-item(@click='section = value')
                  v-list-item-avatar
                    v-icon {{ value.icon }}
                  v-list-item-content
                    v-list-item-title {{ value.name }}
                    v-list-item-subtitle {{ value.description }}
          template(v-else)
            v-list-item(@click='section = null')
              v-list-item-avatar
                v-icon mdi-reply
              v-list-item-content
                v-list-item-title Панель управления
                v-list-item-subtitle Вернуться к главному меню
        template(v-if='section')
          div
            v-list.py-5.mt-2
              .text-h5.text-center.font-weight-light.mb-2 {{ section.name}}
              div.pa-5.pt-0
                v-component(:is='section.component')
</template>

<script>
import UserManage from './UserManage';

export default {
  components: {
    UserManage
  },
 
  data: () => ({
    /** Список функционала */
    features: [{
      name: 'Просмотр списка пользователей',
      description: 'Блокировка, управление кураторами, сброс пароля',
      icon: 'mdi-account-group',

      component: UserManage
    }, {
      name: 'Журнал событий',
      description: 'Просмотр действий кураторов',
      icon: 'mdi-calendar-text'
    }],

    section: null
  }),
  methods: {
  }
}
</script>