const axios = require('axios');
const config = require('../../config');

const getToken = () => localStorage.getItem('token');

const get = (module, query) => {
  return new Promise(async (callback) => {
    try {
      const str = Object.entries(query).filter(([key, value]) => value).map(([key, value]) => `${key}=${value}`).join('&');

      const result = await axios.get(`${config.rest}/${module}?${str}`, {
        headers: {
          authorization: `token ${getToken()}`
        }
      });
  
      return callback({
        success: true,
        data: {
          ...result.data
        }
      });
    }
    catch {
      return callback({
        success: false
      });
    }
  });
}

const post = (module, body) => {
  return new Promise(async (callback) => {
    try {
      const result = await axios.post(`${config.rest}/${module}`, body, {
        headers: {
          authorization: `token ${getToken()}`
        }
      });
  
      return callback({
        success: true,
        data: {
          ...result.data
        }
      });
    }
    catch {
      return callback({
        success: false
      });
    }
  });
}

module.exports = {
  get,
  post,

  signIn: ({ email, password }) => {
    return post('api/admin/login', {
      email,
      password
    });
  },

  validateAuth: () => {
    return post('api/admin/validate');
  },

  paginateUsers: ({ page, onPage, search }) => {
    return get('api/admin/users', { page, onPage, search })
  }
}