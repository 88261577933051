<template lang='pug'>
div
  v-container(fill-height fluid style='justify-content: center; background: whitesmoke;')
    v-card.pa-5(width='500')
      v-container
        .text-h5.font-weight-light.mb-2 Панель управления

        div.px-3
          v-text-field(label='Почта' hide-details v-model='form.email')
          v-text-field(label='Пароль' hide-details type='password' v-model='form.password')

        v-btn.mt-4.white--text.font-weight-light(
          block
          depressed 
          color='teal'
          @click='signIn()'
          style='text-transform: none !important;'
        ) Авторизоваться
</template>

<script>
import placeApi from './../plugins/placeApi';

export default {
  data: () => ({
    form: {
      email: 'admin@gmail.com',
      password: 'GLOBALADMINPASS'
    }
  }),
  methods: {
    /**
     * Авторизоваться в сервисе
     */
    async signIn() {
      const authResult = await placeApi.signIn(this.form);
      if (!authResult.success) {
        alert('Введен неверный логин или пароль!');
        return false;
      }

      localStorage.setItem('token', authResult.data.key);
      this.$router.push('/');
    }
  }
}
</script>