<template lang='pug'>
  v-app
    router-view(style='height: 100vh;')
</template>

<script>
import placeApi from './plugins/placeApi';
export default {
  name: 'App',

  async mounted() {
    // Проверем авторизацию пользователя
    const result = await placeApi.validateAuth();
    if (!result.success) {
      return this.$router.push('/auth');
    }
  },

  data: () => ({
    //
  }),
};
</script>

<style>
.v-application .primary--text {
  color: #009688 !important;
}

p {
  margin: 0 !important;
}

.v-btn__content {
  text-transform: none !important;
  font-weight: 400;
}
</style>