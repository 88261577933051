<template lang='pug'>
div
  v-row(no-gutters justify='center')
    div(style='width: 75%')
      v-text-field(hide-details prepend-icon='mdi-magnify' label='Поиск пользователя по e-mail/login' v-model='pagination.search' @input='provideSearch()')

    v-list
      v-row(no-gutters justify='center')
        template(v-for='value in pagination.result')
          v-col(cols='6')
            v-list-item(@click='selectedUser = value')
              v-list-item-avatar
                v-img(:src='value.avatar' contain)
              v-list-item-content
                v-list-item-title {{ value.username }}
                v-list-item-subtitle {{ value.email }}
              v-list-item-action
                template(v-if='value.roles.includes("moderator")')
                  v-tooltip(bottom='')
                    template(v-slot:activator='{ on, attrs }')
                      v-icon(v-on='on') mdi-police-badge
                    span Администратор

                template(v-else-if='value.roles.includes("curator")')
                  v-tooltip(bottom='')
                    template(v-slot:activator='{ on, attrs }')
                      v-icon(v-on='on') mdi-badge-account
                    span Куратор
                template(v-else)
                  v-tooltip(bottom='')
                    template(v-slot:activator='{ on, attrs }')
                      v-icon(style='opacity: .3' v-on='on') mdi-account
                    span Пользователь

  v-pagination.mt-4(v-model='pagination.page' :length='totalPages' color='teal' total-visible='7' @input='paginateUsers()')

  template(v-if='selectedUser')
    v-dialog(v-model='selectedUser' width='75%')
      v-card
        v-container
          v-row(no-gutters justify='left' align='center')
            div.pr-2
              v-avatar(size='100')
                v-img(:src='selectedUser.avatar')
            v-col()
              .text-h5.font-weight-regular(style='line-height: 1') {{ selectedUser.username }}
              p.font-weight-light(style='line-height: 1') {{ selectedUser.email }}
              
              v-row.pt-2(justify='start' align='center' no-gutters)
                v-tooltip(bottom='')
                  template(v-slot:activator='{ on, attrs }')
                    v-btn(depressed v-on='on' @click='switchBan()' :color='selectedUser.blocked ? "red lighten-2" : ""' :class='{ "white--text": selectedUser.blocked }')
                      v-icon.mr-2(small) mdi-{{selectedUser.blocked ? 'lock' : 'lock-open' }}
                      | {{ selectedUser.blocked ? 'Заблокирован' : 'Активен' }}
                  span Заблокировать пользователя
                v-tooltip(bottom='')
                  template(v-slot:activator='{ on, attrs }')
                    template(v-if='selectedUser.roles.includes("moderator")')
                      v-btn.ml-2.white--text(color='green lighten-1')(depressed v-on='on' @click='switchModerator')
                        v-icon.mr-2(small) mdi-police-badge
                        | Модератор
                    template(v-else)
                      v-btn.ml-2()(depressed v-on='on' @click='switchModerator')
                        v-icon.mr-2(small) mdi-account
                        | Пользователь

                  span {{ selectedUser.roles.includes("moderator") ? "Забрать" : "Выдать" }} права модератора


</template>

<script>
import placeApi from '../plugins/placeApi';
import UserManage from './UserManage';

export default {
  components: {
    UserManage
  },

  mounted() {
    this.paginateUsers();
  },

  computed: {
    /** Общее количество страниц */
    totalPages() {
      const pages = Math.ceil(this.pagination.total / this.pagination.onPage);
      return Math.max(pages, 1);
    }
  },
 
  data: () => ({
    /** То что относится к пагинации */
    pagination: {
      page: 1,  
      onPage: 20,

      total: 0,
      search: null,

      result: []
    },

    selectedUser: null
  }),
  methods: {
    async provideSearch() {
      this.pagination.page = 1;

      this.paginateUsers();
    },

    async paginateUsers() {
      const paginateResult = await placeApi.paginateUsers(this.pagination);
      if (!paginateResult) {
        return false;
      }

      Object.assign(this.pagination, paginateResult.data);
    },

    async switchModerator() {
      const isModerator = this.selectedUser.roles.includes('moderator');

      const switchModeratorResult = await placeApi.post('api/admin/user/moderator', {
        _id: this.selectedUser.id,

        moderator: !isModerator
      });

      if (!switchModeratorResult.success) {
        alert('Ошибка изменения пользователя');
        return;
      }
      
      this.selectedUser = switchModeratorResult.data;

      const original = this.pagination.result.find(v => v._id == this.selectedUser._id);
      original = switchModeratorResult.data;
    },

    async switchBan() {
      const switchBanResult = await placeApi.post('api/admin/user/ban', {
        _id: this.selectedUser.id,

        ban: !this.selectedUser.blocked
      });

      if (!switchBanResult.success) {
        alert('Ошибка изменения пользователя');
        return;
      }
      
      this.selectedUser = switchBanResult.data;

      const original = this.pagination.result.find(v => v._id == this.selectedUser._id);
      original = switchBanResult.data;
    },
  }
}
</script>

<style>
.v-pagination__item {
    background: transparent;
    border-radius: 4px;
    font-size: 1rem;
    height: 34px;
    margin: 0.3rem;
    min-width: 34px;
    padding: 0 5px;
    text-decoration: none;
    transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
    width: auto;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
</style>